import React, { useContext, useEffect } from 'react';

import { graphql, PageProps, navigate } from 'gatsby';
import styled from 'styled-components';

import { B2, H2 } from '../components/Atoms/Typography/Typography';
import CTABanner from '../components/FlexibleComponents/CTABanner/CTABanner';
import ResourceSearch from '../components/ResourceSearch';
import SEO from '../components/SEO/SEO';
import { SiteFooter } from '../components/SiteFooter/SiteFooter';
import { SiteGrid } from '../components/SiteGrid/SiteGrid';
import SiteHeader from '../components/SiteHeader/SiteHeader';
import Wrapper from '../components/Wrapper/Wrapper';
import PageContext from '../hooks/pageContextProvider';
import { renderRichTextNode } from '../hooks/useRichText';
import colorTheme from '../style/colorTheme';
import { desktopOnly } from '../style/desktopHelpers';
import { ITheme } from '../types/types';
import replaceLocaleUrl from '../utils/replaceLocaleUrl';

const Hero = styled.section<ITheme>`
  ${(props) => colorTheme(props.ezratheme)};
  padding: 80px 0 40px;
  background-color: var(--background);

  .eyebrow,
  .intro-copy {
    grid-column: 1 / -1;
    color: var(--primary);
  }

  .section-subhead {
    grid-column: 1 / -1;
    margin-bottom: 16px;
    color: var(--highlight);
    word-break: break-word;
  }

  ${desktopOnly} {
    padding-top: 64px;

    .eyebrow {
      margin-bottom: 8px;
    }

    .section-subhead {
      margin-bottom: 24px;
    }
  }
`;

const ResourceOverviewPage: React.FC<PageProps<any>> = (props: any) => {
  const website = props.data?.contentfulWebsite;
  const pageData = props.data?.contentfulComposePage;
  const seo = pageData?.seo;
  const headerData = props.data?.allContentfulComponentSiteHeader?.nodes?.[0];
  const stickyBanner = props.data?.allContentfulComponentStickyBanner?.nodes;
  const footerData = props.data?.allContentfulComponentSiteFooter?.nodes?.[0];
  const customTheme = pageData?.customTheme;
  const content = pageData?.content?.sections;
  const microCopy = props.data?.allContentfulContentMicrocopy?.nodes?.[0];
  const slug = pageData?.slug;
  const location = props.location;
  const alternativeLocales = props.data?.alternativeLocales?.distinct || [];

  const HeroContent = content?.[0]?.content;
  const searchContent = content?.[2];
  const CTABannerContent = content?.[3];

  const imageCopyCarousel =
    props.data?.contentfulComposePage?.content?.sections?.[4];
  const { locale } = useContext(PageContext);

  useEffect(() => {
    const isFrenched = localStorage.getItem('isFrenched');
    if (isFrenched) {
      return;
    }

    fetch('https://ipapi.co/json/', { method: 'GET' })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data?.region === 'Quebec') {
          localStorage.setItem('isFrenched', 'true');
          const neededUrl = replaceLocaleUrl(location?.pathname, 'fr-fr');
          return navigate(neededUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <SEO
        siteName={website?.siteName}
        title={pageData.title}
        description={
          seo?.description ? seo?.description.description : pageData.title
        }
        location={location}
        alternativeLocales={alternativeLocales}
        slug={slug}
        noIndex={seo?.seoIndex === 'No'}
        image={seo?.shareImage}
        locale={locale}
      />
      <SiteHeader
        content={headerData}
        stickyBanner={stickyBanner}
        customTheme={customTheme}
      />
      <Hero ezratheme="Dark">
        <Wrapper>
          <SiteGrid>
            <H2 as="h1" className="section-subhead">
              {HeroContent.primaryText}
            </H2>
            <B2 className="intro-copy" as="div">
              {renderRichTextNode(HeroContent.bodyText)}
            </B2>
          </SiteGrid>
        </Wrapper>
      </Hero>
      <ResourceSearch
        copy={microCopy}
        searchContent={searchContent}
        section={imageCopyCarousel}
      />
      <CTABanner key={CTABannerContent.id} {...CTABannerContent} />

      {/* <div>
        <VideoCopyHero {...content?.[0]} />
      </div>

      {slug === 'news-and-events' ? (
        <NewsAndEventsPageSearch
          copy={microCopy}
          searchContent={searchContent}
          section={imageCopyCarousel}
        />
      ) : slug === 'learning-hub' ? (
        <LearningHubPageSearch
          copy={microCopy}
          searchContent={searchContent}
          section={imageCopyCarousel}
        />
      ) : (
        <ResourceSearch
          copy={microCopy}
          searchContent={searchContent}
          section={imageCopyCarousel}
        />
      )}
      <RedesignCTABanner key={CTABannerContent.id} {...CTABannerContent} /> */}
      <SiteFooter {...footerData} customTheme={customTheme} />
    </>
  );
};

export default ResourceOverviewPage;

export const data = graphql`
  query ResourceOverviewPageQuery(
    $id: String
    $locale: String
    $contentful_id: String
  ) {
    contentfulWebsite {
      siteName
    }
    alternativeLocales: allContentfulComposePage(
      filter: {
        contentful_id: { eq: $contentful_id }
        publishInLocale: { eq: "Yes" }
      }
    ) {
      distinct(field: node_locale)
    }
    contentfulComposePage(id: { eq: $id }) {
      id
      contentful_id
      title
      slug
      customTheme
      content {
        ...ResourceOverviewFragment
      }
      seo {
        ...SEOFragment
      }
    }

    allContentfulComponentStickyBanner(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        ...StickyBannerFragment
      }
    }

    allContentfulComponentSiteHeader(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...SiteHeaderFragment
      }
    }

    allContentfulComponentSiteFooter(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...SiteFooterFragment
      }
    }

    allContentfulContentMicrocopy(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...MicroCopyFragment
      }
    }

    allContentfulContentResourceType(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        primaryText
        slug
      }
    }
  }
`;
