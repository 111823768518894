import React, { useContext } from 'react';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styled, { css } from 'styled-components';

import PageContext from '../../../hooks/pageContextProvider';
import { getLocalePage } from '../../../hooks/useLocale';
import { renderRichTextNode } from '../../../hooks/useRichText';
import colorTheme from '../../../style/colorTheme';
import { desktopOnly, useIsDesktop } from '../../../style/desktopHelpers';
import themeGet from '../../../style/themeGet';
import { ITheme } from '../../../types/types';
import { ButtonLink } from '../../Atoms/Button/Button';
import ContentfulImage from '../../Atoms/ContentfulImage/ContentfulImage';
import Icon from '../../Atoms/Icon/Icon';
import { B2, Caption, H3 } from '../../Atoms/Typography/Typography';
import { HomeGrid } from '../../SiteGrid/SiteGrid';
import Wrapper from '../../Wrapper/Wrapper';

interface ABCarouselProps {
  ezratheme: ITheme['ezratheme'];
  slides: {
    primaryText: string;
    secondaryText: string;
    bodyText: { raw: string; references: [] };
    link: {
      page: {
        slug: string;
      };
      text: string;
    };
  }[];
}

const ABContainer = styled.div<ITheme>`
  ${(props) => colorTheme(props.ezratheme)};
  color: var(--background);

  .slide-outer {
    background-color: var(--background);
  }

  .carousel-provider {
    margin: 0 -30px;
  }

  ${desktopOnly} {
    padding: 0;

    ${(props) => {
      if (props.ezratheme !== 'Dark') {
        return css`
          //padding: 40px 0;
          background-color: var(--background);
        `;
      }
    }}

    .carousel-provider {
      margin: 0;
    }
  }
`;

const StyledWrapper = styled(Wrapper)`
  ${desktopOnly} {
    padding-left: 0;
  }
`;

const MediaContainer = styled.div`
  width: 100%;
  min-height: 250px;
  max-height: 525px;
  color: ${themeGet('color.secondary.salmon')};
  display: flex;

  .gatsby-image-wrapper {
    width: 100%;
  }

  img {
    object-fit: cover !important;
  }

  ${desktopOnly} {
    grid-column: span 4;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 30px 15px;
  color: var(--primary);

  ${desktopOnly} {
    grid-column: span 4;
    padding-left: 15px;
    max-width: 550px;
    padding-top: ${themeGet('spacing.s6')};
  }
`;

const StyledB2 = styled(B2)`
  margin-bottom: ${themeGet('spacing.s6')};
`;

const StyledH3 = styled(H3)`
  color: var(--highlight);
  margin-bottom: ${themeGet('spacing.s6')};
  line-height: 1.2;
`;

const StyledCaption = styled(Caption)`
  color: var(--primary);
  margin-bottom: 15px;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themeGet('spacing.mobile.s5')} 0;
  background-color: var(--background);

  .article-title-link {
    white-space: normal;
    line-height: 1.25;
    margin-right: 16px;
    ${desktopOnly} {
      margin-right: 0;
    }
  }

  ${desktopOnly} {
    margin-top: ${themeGet('spacing.s5')};
    align-items: flex-end;
    justify-content: space-between;
    padding: 0;
  }
`;

const Controls = styled.div`
  padding: 5px 0;
  height: 46px;
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 70px;

  .left-controls-btn {
    border-right: 1px solid var(--primary);
    padding-right: 17px;
  }
`;

const StyledNavButton = styled(ButtonBack)`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 17px;
  padding-right: 17px;
  justify-content: center;
  //transition: all 0.3s ease-in-out;

  /* &:hover,
  &:focus {
    background-color: var(--highlight);
    border: 1px solid var(--highlight);
    svg {
      path {
        stroke: var(--background);
      }
    }
  } */
`;

const StyledChevron = styled(Icon)`
  svg {
    path {
      stroke: var(--primary);
    }
  }
`;

const ChevronLeft = styled(StyledChevron)`
  //transform: rotate(180deg);
`;

const StyledSiteGrid = styled(HomeGrid)`
  display: block;
  ${desktopOnly} {
    display: flex;
    height: 100%;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  color: var(--highlight);
  border: 1px solid var(--highlight);
  border-radius: 70px;
  padding: 14px 17px 14px 14px;
  width: 200px;
  &:hover,
  &:focus {
    background-color: var(--highlight);
    color: var(--background);
  }
  :after {
    display: none;
  }
`;

const ABCarousel = ({ slides, ezratheme = 'Dark' }: ABCarouselProps) => {
  const isDesktop = useIsDesktop();
  const { locale } = useContext(PageContext);

  return (
    <ABContainer ezratheme={ezratheme}>
      <StyledWrapper>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          isIntrinsicHeight={true}
          totalSlides={slides?.length}
          infinite={true}
          touchEnabled={false}
          className="carousel-provider"
        >
          <Slider role="region" aria-label="Carousel">
            {slides?.map((slide, index) => {
              return (
                <Slide
                  key={index}
                  index={index}
                  className="slide-outer"
                  role="group"
                  aria-selected={null}
                >
                  <StyledSiteGrid>
                    <MediaContainer>
                      {slide.featureImage && (
                        <ContentfulImage
                          image={slide.featureImage?.[0]}
                          objectFit="fill"
                        />
                      )}
                    </MediaContainer>
                    <Content>
                      {slide.secondaryText && (
                        <StyledCaption>{slide.secondaryText}</StyledCaption>
                      )}
                      {slide.primaryText && (
                        <StyledH3>{slide.primaryText}</StyledH3>
                      )}

                      <StyledB2>
                        {slide.bodyText?.raw &&
                          renderRichTextNode(slide.bodyText)}
                      </StyledB2>

                      {isDesktop && slide.link?.[0]?.text && (
                        <StyledButtonLink
                          variant="secondary"
                          ezratheme={ezratheme}
                          size={isDesktop ? 'large' : 'small'}
                          ariaLabel={`${slide.link?.[0]?.text} on a new page`}
                          to={getLocalePage(slide.link?.[0], locale)}
                        >
                          {slide.link?.[0]?.text}
                        </StyledButtonLink>
                      )}
                      {isDesktop && (
                        <ControlsContainer>
                          {slides?.length > 1 && (
                            <Controls
                              role="group"
                              aria-label="Slide controls"
                              aria-selected={null}
                            >
                              <StyledNavButton
                                className="left-controls-btn"
                                aria-label="Previous slide"
                              >
                                <ChevronLeft
                                  icon="buttonArrowLeft"
                                  size={12}
                                ></ChevronLeft>
                              </StyledNavButton>
                              <StyledNavButton
                                as={ButtonNext}
                                aria-label="Next slide"
                              >
                                <StyledChevron
                                  icon="buttonArrowRight"
                                  size={12}
                                ></StyledChevron>
                              </StyledNavButton>
                            </Controls>
                          )}
                        </ControlsContainer>
                      )}
                    </Content>
                  </StyledSiteGrid>
                </Slide>
              );
            })}
          </Slider>
          {!isDesktop && (
            <ControlsContainer>
              {slides?.[0]?.link?.[0]?.text && (
                <StyledButtonLink
                  variant="secondary"
                  ezratheme={ezratheme}
                  size={isDesktop ? 'large' : 'small'}
                  ariaLabel={`${slides?.[0]?.link?.[0]?.text} on a new page`}
                  to={getLocalePage(slides?.[0]?.link?.[0], locale)}
                  className="article-title-link"
                >
                  {slides?.[0]?.link?.[0]?.text}
                </StyledButtonLink>
              )}
              {slides?.length > 1 && (
                <Controls
                  role="group"
                  aria-label="Slide controls"
                  aria-selected={null}
                >
                  <StyledNavButton>
                    <ChevronLeft icon="chevronRight" size={16}></ChevronLeft>
                  </StyledNavButton>
                  <StyledNavButton as={ButtonNext}>
                    <StyledChevron
                      icon="chevronRight"
                      size={16}
                    ></StyledChevron>
                  </StyledNavButton>
                </Controls>
              )}
            </ControlsContainer>
          )}
        </CarouselProvider>
      </StyledWrapper>
    </ABContainer>
  );
};

export default ABCarousel;
