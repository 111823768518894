import React, { useState, useMemo, useContext, useEffect } from 'react';

import algoliasearch from 'algoliasearch';
import {
  InstantSearch,
  connectSearchBox,
  Index,
  connectInfiniteHits,
  Configure,
  connectStateResults,
  connectRefinementList,
} from 'react-instantsearch-dom';
import styled from 'styled-components';

import PageContext from '../../hooks/pageContextProvider';
import { desktopOnly } from '../../style/desktopHelpers';
import fontGet from '../../style/fontGet';
import themeGet from '../../style/themeGet';
import { richTextToPlainText } from '../../utils/richTextToPlainText';
import { Button } from '../Atoms/Button/Button';
import Icon from '../Atoms/Icon/Icon';
import { Caption } from '../Atoms/Typography/Typography';
import TextField from '../Forms/TextField/TextField';
import ABCarousel from '../Molecules/Carousel/ABCarousel';
import ResourceCard from '../Molecules/ResourceCard/ResourceCard';
import Wrapper from '../Wrapper/Wrapper';

const ResultsSection = styled.div``;

const ResourceSearchSection = styled.div`
  ${desktopOnly} {
    margin-bottom: ${themeGet('spacing.mobile.s3')};
  }
`;

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  flex-shrink: 1;
`;

const NewestResourceCardContainer = styled.div`
  background-color: ${themeGet('color.background.lightBeige')};
  width: 100%;
  margin: 0 auto;
  max-width: 100vw;

  ${desktopOnly} {
    padding: 0 40px;
    max-width: 1440px;
  }
`;

const NewestResourceCardWrapper = styled.div`
  margin-bottom: 32px;
`;

const HitCountCaption = styled(Caption)`
  margin: ${themeGet('spacing.s5')} 0;
`;

const ClearContainer = styled.div`
  ${desktopOnly} {
    top: 63%;
  }
  position: absolute;
  right: 120px;
  top: 52%;
  transform: translateY(-50%);
  button {
    border: none;
    background: none;
    cursor: pointer;
  }
`;

const SearchButton = styled.button`
  ${desktopOnly} {
    height: 64px;
    top: 60%;
    padding: 20px;
  }
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${themeGet('color.primary.graphite')};
  color: ${themeGet('color.primary.white')};
  padding: 10px 20px;
  height: 47px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
`;

const SearchForm = styled.form``;

const LoadMoreSection = styled.div`
  display: flex;
  justify-content: center;
  margin: ${themeGet('spacing.s4')} 0;
`;

const SearchBarWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  div:first-child {
    flex-grow: 1;
  }

  ${desktopOnly} {
    margin-top: ${themeGet('spacing.s6')};
    padding: 0 40px;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 32px;
  }
`;

const SearchContainer = styled.div`
  @media (max-width: ${themeGet('breakpoints.desktop')}) {
    > div {
      margin-top: 0;
    }
  }
  position: relative;
  display: flex;
  align-items: flex-end;
  input {
    ${desktopOnly} {
      height: 64px;
    }
    border: 1px solid grey;
    height: 48px;
    padding: 15px;
    margin: 0;
  }
  & input::placeholder,
  & textarea::placeholder {
    color: ${themeGet('color.primary.graphite')};
  }
`;

const updateQuery = (param, value) => {
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = urlSearchParams.entries();

    const newURL = new URL(window.location.href);

    const paramArray = Array.from(params);
    if (paramArray.length) {
      let query = '?';
      if (value) {
        query = `?${param}=${value}&`;
      }
      paramArray.forEach((e) => {
        if (e[0] !== param) {
          query += `${e[0]}=${e[1]}&`;
        }
      });
      newURL.search = query.slice(0, -1);
    } else {
      newURL.search = `?${param}=${value}`;
    }
    window.history.replaceState({ path: newURL.href }, '', newURL.href);
  }
};

const getParam = (param) => {
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params[param] || '';
  }
};

const TypeListContainer = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  background-color: ${themeGet('color.primary.yellow')};
  color: ${themeGet('color.primary.graphite')};
  align-items: center;

  button:last-child {
    margin-right: 0;
  }

  div {
    display: flex;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      background: transparent;
      height: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-right: 32px;
  ${({ active }) =>
    active &&
    `
    border-bottom: 1px solid black;
  `};
`;

const RefineTypeList = ({ refine, currentRefinement }) => {
  const { resourceTypes, microCopy } = useContext(PageContext);
  const types = [
    { id: '', title: microCopy?.globalAll },
    {
      id: 'insights',
      title: resourceTypes?.find((type) => type.slug === 'insights')
        ?.primaryText,
    },
    {
      id: 'research',
      title: resourceTypes?.find((type) => type.slug === 'research')
        ?.primaryText,
    },
    {
      id: 'case-studies',
      title: resourceTypes?.find((type) => type.slug === 'case-studies')
        ?.primaryText,
    },
    {
      id: 'news',
      title: resourceTypes?.find((type) => type.slug === 'news')?.primaryText,
    },
    {
      id: 'events',
      title: resourceTypes?.find((type) => type.slug === 'events')?.primaryText,
    },
    {
      id: 'zest',
      title: resourceTypes?.find((type) => type.slug === 'zest')?.primaryText,
    },
  ];

  const activeTab = getParam('type');
  const [active, setActive] = useState(currentRefinement);

  const setRefinement = (type) => {
    updateQuery('type', type);
    refine(type);
    setActive(type);
  };

  useEffect(() => {
    if (activeTab !== active) {
      refine(activeTab);
      setActive(activeTab);
    }
  }, [activeTab]);

  return (
    <TypeListContainer>
      <Wrapper>
        {types.map((type, index) => (
          <StyledButton
            key={`type-button-${index}`}
            variant="secondary"
            active={activeTab === type.id}
            onClick={() => setRefinement(type.id)}
            aria-current={activeTab === type.id ? 'true' : 'false'}
            ariaLabel={`${
              microCopy?.resourcesCaseStudiesExploreMoreText?.split(' ')[0]
            } ${type.title}`}
          >
            {type.title}
          </StyledButton>
        ))}
      </Wrapper>
    </TypeListContainer>
  );
};

const TopicContainer = styled.div`
  width: 100%;
  select {
    ${desktopOnly} {
      height: 64px;
      padding: 15px;
    }
    width: 100%;
    border-radius: 0px;
    height: 48px;
    ${fontGet('h4')};
    padding: 10px;
    margin-top: 20px;
  }
  ${desktopOnly} {
    width: 20%;
    margin-top: 20px;
  }
`;

const RefineTopicList = ({ refine, defaultRefinement, content }) => {
  const topics = content.options;
  const label = content.label.raw;

  const setRefinement = (topic) => {
    updateQuery('topic', topic);
    refine(topic);
  };

  return (
    <TopicContainer>
      <select
        onChange={(e) => setRefinement(e.target.value)}
        defaultValue={defaultRefinement?.[0] ?? ''}
        aria-label={richTextToPlainText(label)}
      >
        {topics.map((topic, index) => (
          <option key={`option-${topic.key}-${index}`} value={topic.key}>
            {topic.value}
          </option>
        ))}
      </select>
    </TopicContainer>
  );
};

const CustomTypeRefine = connectRefinementList(RefineTypeList);
const CustomTopicRefine = connectRefinementList(RefineTopicList);

const SearchBox = ({
  refine,
  currentRefinement,
  searchContent,
  onInputSearch,
}) => {
  const [searchKeyword, setSearchKeyword] = useState(getParam('searchKeyword'));

  const handleInputSearch = (event) => {
    const inputValue = event.target.value;
    setSearchKeyword(inputValue);
    if (onInputSearch) {
      onInputSearch(inputValue);
    }
  };

  const topicContent = searchContent.formFields[1];

  return (
    <SearchForm
      id={`search-form-${searchContent?.id}`}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        updateQuery('searchKeyword', searchKeyword);
        refine(searchKeyword);
      }}
    >
      <CustomTypeRefine
        attribute="type"
        defaultRefinement={[getParam('type')]}
      />
      <SearchBarWrapper role="search">
        <SearchContainer>
          <TextField
            isResourcesSearch={true}
            id="searchKeyword"
            name="searchKeyword"
            value={searchKeyword}
            onChange={handleInputSearch}
          />
          {currentRefinement && (
            <ClearContainer>
              <button
                type="button"
                aria-label="clear search"
                onClick={(e) => {
                  setSearchKeyword('');
                  refine('');
                  updateQuery('searchKeyword', '');
                  if (onInputSearch) {
                    onInputSearch('');
                  }
                }}
              >
                <Icon icon="close" size={20} />
              </button>
            </ClearContainer>
          )}
          <SearchButton>Search</SearchButton>
        </SearchContainer>
        <CustomTopicRefine
          attribute="topics"
          defaultRefinement={[getParam('topic')]}
          content={topicContent}
        />
      </SearchBarWrapper>
    </SearchForm>
  );
};

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > -1 ? (
    <HitCountCaption
      role="status"
      aria-live="polite"
    >{`${hitCount} Results Found`}</HitCountCaption>
  ) : null;
});

const getCTAText = (type, copy) => {
  switch (type) {
    case 'insights':
      return copy.resourcesInsightsCardLinkText;
    case 'research':
      return copy.resourcesResearchCardLinkText;
    case 'case-studies':
      return copy.resourcesCaseStudiesCardLinkText;
    case 'news':
      return copy.resourcesNewsCardLinkText;
    case 'events':
      return copy.resourcesEventsArchivedEvenLinkText;
    case 'zest':
      return copy.resourcesZestCardLinkText;
    default:
      return '';
  }
};

const Hits = ({ hits, hasMore, refineNext, copy, showNewestResource }) => (
  <>
    <Wrapper>
      <HitCount />
    </Wrapper>
    <ResultsSection>
      {hits.length > 0 && showNewestResource && (
        <NewestResourceCardContainer>
          <NewestResourceCardWrapper>
            <ResourceCard
              isNewestCard
              key={hits[0].objectID}
              title={hits[0].title}
              date={hits[0].publishDate}
              imageURL={hits[0].imageUrl}
              resourceType={hits[0].type}
              link={hits[0].slug}
              buttonText={getCTAText(hits[0].type, copy)}
              bodyText={
                hits[0].introText
                  ? richTextToPlainText(hits[0].introText)
                  : richTextToPlainText(hits[0].bodyText).slice(0, 300) + '...'
              }
            />
          </NewestResourceCardWrapper>
        </NewestResourceCardContainer>
      )}
      <Wrapper>
        <CardGrid>
          {hits.slice(showNewestResource ? 1 : 0).map((hit) => (
            <ResourceCard
              isNewestCard={false}
              key={hit.objectID}
              title={hit.title}
              date={hit.publishDate}
              imageURL={hit.imageUrl}
              resourceType={hit.type}
              link={hit.slug}
              buttonText={getCTAText(hit.type, copy)}
            />
          ))}
        </CardGrid>
        <LoadMoreSection>
          {hasMore && (
            <Button onClick={refineNext}>
              {copy?.resourcesSearchResultsLoadMore}
            </Button>
          )}
        </LoadMoreSection>
      </Wrapper>
    </ResultsSection>
  </>
);

const SearchBoxContainer = connectSearchBox(SearchBox);

const HitsContainer = connectInfiniteHits(Hits);

const ResourceSearch = ({ copy, searchContent, section }) => {
  const [query, setQuery] = useState();
  const [showNewestResource, setShowNewestResource] = useState(true);

  const onInputSearch = (inputValue) => {
    setShowNewestResource(!inputValue);
  };
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      ),
    [],
  );

  const { locale } = useContext(PageContext);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`Resources_${locale}_${process.env.GATSBY_ALGOLIA_ENVIRONMENT}`}
      onSearchStateChange={({ query }) => setQuery(query)}
    >
      <Configure hitsPerPage={9} />
      {section && (
        <ABCarousel
          key={section.id}
          slides={section.contentItems}
          ezratheme={section.appearance?.theme}
        />
      )}
      <ResourceSearchSection>
        <SearchBoxContainer
          defaultRefinement={getParam('searchKeyword')}
          searchContent={searchContent}
          onInputSearch={onInputSearch}
        />
      </ResourceSearchSection>

      <Index
        indexName={`Resources_${locale}_${process.env.GATSBY_ALGOLIA_ENVIRONMENT}`}
      >
        <HitsContainer copy={copy} showNewestResource={showNewestResource} />
      </Index>
    </InstantSearch>
  );
};

export default ResourceSearch;
